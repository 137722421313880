import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/config'
import '@/assets/style/index.less'
import {_isMobile} from '@/utils/index'
import VueLazyLoad from 'vue-lazyload'

// 引入rem
import '@/utils/flexible.js'
import 'echarts/lib/component/tooltip'
import BaiduMap from 'vue-baidu-map'
import Router from "./router";
Vue.use(BaiduMap, {
  /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
  ak: 'Xv866NcNqsH2MlOweVUjPBMObi2ECYjQ'
})
//图片懒加载
Vue.use(VueLazyLoad,{
  loading:'/loading-svg/loading-bars.svg',
  // throttleWait:1000  //等待多少秒
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.directive('loadmore', {
  bind (el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', function () {
      let sign = 0
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (scrollDistance <= sign) {
        binding.value()
      }
    })
  }
})
