<template>
  <div v-if="isMobile" class="footerBoxPhone">
    <div class="footer_phone">
      <div class="arr_phone">
        <i>
          <img src="../../assets/weizhi.png">
        </i>
     <p>
       北京大兴区金星路18号院6栋1层
     </p>
      </div>
      <div class="footerRight_phone"
      >
        <p >关注我们</p>
        <el-popover
          placement="top-start"
          width="120"
          trigger="hover"
        >
          <div style="width:25vw;height:25vw;">
            <img
              style="width:100%;height:100%;object-fit: cover;"
              src="../../assets/QRcode.jpg"
              alt
            />
          </div>
          <div slot="reference" style="width: 2vw;height: 2vw;display: flex;align-items: center">
            <img src="../../assets/weixinIcon.png">
          </div>

        </el-popover>

      </div>
    </div>
    <div class="footer_phone" style="margin-top: 2vw">
      <p>
        版权所有：北京久感科技有限公司
      </p>
      <a href="https://beian.miit.gov.cn/" style="color: white">京ICP备16047537号-1</a>

    </div>
    <el-row :gutter="20" style="font-size: 3vw;text-align: center;margin-top: 2vw;background: green">
      <el-col :span="12">
      </el-col>
      <el-col :span="12">
      </el-col>
    </el-row>
  </div>
  <div v-else class="footerBox">
    <el-row class="footer" type="flex" align="center" :gutter="20">
      <el-col :span="8">
        <div class="logo">
          <i>
            <img src="../../assets/weizhi.png">
          </i>
          北京大兴区金星路18号院6栋1层
        </div>
      </el-col>
      <el-col :span="8 ">
        <el-row :gutter="20">
          <el-col :span="12">
            版权所有：北京久感科技有限公司
          </el-col>
          <el-col :span="12">
            <a href="https://beian.miit.gov.cn/" style="color: white">京ICP备16047537号-1</a>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" class="footerRight"
      >
        <p style="width: 20%;height: 100%;font-size: 20px">关注我们</p>
        <el-popover
          placement="top-start"
          width="120"
          trigger="hover"
        >
          <div style="width:100px;height:100px;">
            <img
              style="width:100%;height:100%;object-fit: cover;"
              src="../../assets/QRcode.jpg"
              alt
            />
          </div>
          <div slot="reference" style="width: 100%;height: 100%;display: flex;align-items: center">
            <img src="../../assets/weixinIcon.png">
          </div>

        </el-popover>

      </el-col>
    </el-row>
  </div>
</template>
<script>
  import {_isMobile} from '@/utils/index'

  export default {
    data() {
      return {
        navLength: 0,
        isMobile: false,
        isDivActive: -1,
      }
    },
    methods: {

      //路由点击跳转
      toTabs(index) {
        this.isDivActive = index
        this.$router.replace(this.navTab[index].src)

      }
    },
    created() {
      if (_isMobile()) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    mounted() {
      // 通过判断导航栏tab长度控制span长度
    }
  }
</script>

<style scoped lang="less">
  .footerBox {
    background: red;
    width: 100%;
    bottom: 0px;

    left: 0;
  }

  .footer {
    width: 100%;
    height: 10vh;
    box-sizing: border-box;
    padding: 0px 12%;
    left: 0;
    line-height: 10vh;
    text-align: center;
    color: white;
    font-weight: 600;
    background: #2a2b2c;
    margin-left: 0px !important;;
  }

  .footerRight {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 40%;
      margin-left: 10%;
    }
  }
  .logo {
    text-align: left;
    text-align: center;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: inline-block;
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      height: 20%;
      margin: auto;
      width: 16%;

    }
  }
  .navTab {
    div {
      width: 100%;
      height: 80%;

    }

    div:hover {
      color: #0D9EFA;
      box-shadow: 0px 0px 15px black;
    }

    div:active {
      color: #005eff;
    }

    .divActive {
      color: #0D9EFA;
      font-weight: 600;
      font-size: 120%;
      box-shadow: 0px 0px 10px #0D9EFA;

    }
  }


</style>
<style scoped lang="less">
  .footerBoxPhone {
    z-index: 999;

position:fixed;;

    background-image: linear-gradient(to bottom, #5b5b5b, #1a1a1a);
    /*height: 15vw;*/
    width: 100%;
    padding: 2vw 2vw;
    font-size: 3vw;
    color: white;
    .footer_phone{
      display: flex;
      width: 100%;
      align-items: center;
    justify-content: space-between;
      .arr_phone{
        height: 100%;
          text-align: center;
          width: 100%;
          align-items: center;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          i {
            width: 10%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            height: 20%;
            margin: auto;
            width: 80%;

        }
      }
      .footerRight_phone{
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p{
          margin-right: 2vw;
          font-size: 4vw;
        }
        img{
          width: 10vw;
        }
      }
    }

  }


</style>
