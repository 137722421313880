import Vue from 'vue'
import VueRouter from 'vue-router'
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {undefined
  return routerPush.call(this, location)
}
Vue.use(VueRouter)

// 所以新增菜单路由，存放在该数组中。会根据路由权限表自动挂载路由
/** ***********************************************************************************
 *  特殊参数说明
 * meta: routeMatch 当存在用路由 /login/admin这种方式通过param传参时，通过routeMatch来匹配路由
 *
 **************************************************************************************/

// 白名单路由，即不再权限控制范围内的路由
const constantRouterMap = [
  {
    path: '*' ,
    redirect: '/home', //重定向
  },
  {
    path: '/home',
    name: '北京久感科技有限公司',
    component: () => import('@/views/home'),
  },
  {
    path: '/about-company',
    name: 'about-company',
    component: () => import('@/views/aboutCompany'),
  },
  {
    path: '/product-center',
    name: '北京久感科技有限公司',
    component: () => import('@/views/product/productCenter'),
    children: [
      {
        path: '',
        redirect: '/productAll', //重定向

      },
      {
        path: '/productAll',//path设为空，就是默认展示+的看板
        name: 'productAll',
        meta: {name: 'productAll'},
        component: () => import('@/views/product/all.vue'),
      },
      {
        path: '/product/detail/:id/',
        name: '产品详情页',
        component: () => import('@/views/product/details'),
      },

    ]
  },
  {
    path: '/successful-cases',
    name: 'successful-cases',
    component: () => import('@/views/successful/successfulCases'),
  },
  {
    path: '/case/detail/:id/',//path设为空，就是默认展示+的看板
    name: 'casesDetail',
    meta: {name: 'cases'},
    component: () => import('@/views/successful/cases.vue'),
  },
  {
    path: '/news-center',//path设为空，就是默认展示+的看板
    name: 'news-center',
    component: () => import('@/views/news/newsCenter'),
  },
  {
    path: '/news/detail/:id/',
    name: 'newsDetail',
    meta: {name: 'news'},
    component: () => import('@/views/news/news.vue'),
  },

  {
    // 404页面
    path: '*',
    component: () => import('@/components/error/404')
  },

]

// 将路由封装在一个异步函数中，方便重置路由
const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRouterMap
})

const router = createRouter()
export default router


// 最终挂载到frameworkRouter => children的白名单路由 即在 @/views/layout 框架下渲染
export const layoutWhiteRouter = [

  {
    // 404页面
    path: '*',
    component: () => import(/* webpackChunkName: "error" */'@/components/error/404')
  },
]

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
  } else {
  }
});


// 路由白名单，权限之外
let WHITE_LIST = ['/news-center','/home','/about-company','/product-center','/successful-cases','/news-center']
WHITE_LIST = [...WHITE_LIST, ...layoutWhiteRouter.map(x => x.path)]
export {WHITE_LIST}
