import {
  getWebsiteList,
  getWebsiteDetail
} from '@/services/model/website'
import constUrl from '@/services/env'
import Format from '@/utils/format'
import {_isMobile} from '@/utils/index'
import FooterBox from '@/views/layout/FooerBox'

export default {

  props: ['roadId'],
  data() {
    return {
      isMobile: false,
      heardMenuList: [],
      constUrl
    }
  },
  components: {
    FooterBox,
  },
  computed: {},
  filters: {
    // 时间格式化
    formatTimer(timer) {
      if (timer) {
        return Format(new Date(timer))
      }
      return ''
    },
  },
  methods: {
    //点击富文本触发的方法
    clickDom(event) {
    },
    ///////////////操作开始////////////////
    setActiveItem(item) {
      //激活的菜单对象
      sessionStorage.setItem("ActiveItem", JSON.stringify(item));
    },
    getActiveItem() {
      //激活的菜单对象
      return JSON.parse(sessionStorage.getItem("ActiveItem"));
    },
    ///////////////缓存操作结束////////////////
    //获取顶部菜单列表
    async getHeadMenuList() {
      let heardMenuList = JSON.parse(sessionStorage.getItem('HeardMenuList'))
      if (!heardMenuList) {
        let {data} = await getWebsiteList()
        heardMenuList = data
        this.setActiveItem(heardMenuList[0])
        sessionStorage.setItem("HeardMenuList", JSON.stringify(data));
      }
      return heardMenuList || []
    },
    //通过id获取富文本详情
    async getDetailById(id) {
      let detail = JSON.parse(sessionStorage.getItem(id))
      if (!detail) {
        let {data} = await getWebsiteDetail({id: id})
        detail = data
        sessionStorage.setItem(id, JSON.stringify(data));
      }
      return detail;
    },
    //通过获取当前激活对象子列表
    async getChildList(page, pageSize) {
      let item = this.getActiveItem()
      //使用对象id作为缓存key，获取子列表
      let itemChildList = JSON.parse(sessionStorage.getItem(item.url));
      if (!itemChildList) {
        let heardMenuList = await this.getHeadMenuList();
        let father = heardMenuList.find(x => x.id === item.id);
        itemChildList = father.itemList
        sessionStorage.setItem(item.url, JSON.stringify(itemChildList));
      }
      if (page && pageSize) {
        return itemChildList.slice((page - 1) * pageSize, page * pageSize)
      }
      return itemChildList
    },
  },
  created() {
    if (_isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }
}
