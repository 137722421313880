import Axios from 'axios'
import baseUrl from './env'
Axios.defaults.baseURL = baseUrl

// 该axios实例用于权限接口
let Authority = Axios.create({
  timeout: 45000
})
// 请求发前拦截，header中添加token
Authority.interceptors.request.use(res => {
  return res
})

// 下载流文件
export const postBolb = (url, params) => Authority({
  url,
  data: params,
  method: 'post',
  responseType: 'blob'
})

// 下载流文件
export const getBolb = (url, params) => Authority({
  url,
  params: params?.params,
  method: 'get',
  responseType: 'blob'
})

export { Authority }

// 该实例用于 不需要token的请求
export const General = Axios.create({
  timeout: 45000
})

// 后端接口针对null  '' 做了区分。
// 例如在查询的时候  {name: ''}不是查所有的数据，而是查  name === ''的数据。
// 所以需要做特殊处理，将所有的''去除
// *************************这里只做了一层遍历，没有做深度遍历 *********
export const filterEmpty = obj => {
  // 只针对对象进行过滤
  if (Object.prototype.toString.call(obj) !== '[object Object]') return false
  let map = {}
  for (let key in obj) {
    // 黑名单 才对象中的黑名单字段过滤掉
    let blackList = ['', null, undefined]
    let value = obj[key]
    if (!blackList.includes(value)) map[key] = value
  }
  return map
}
