import {Authority} from "../request";

const { post, get } = Authority

//
// 官网一级菜单
export const getWebsiteList= params => get('/open-web/v1/queryMenu', { params })
// 官网分页列表
export const getWebsiteQueryPageList= params => get('/open-web/v1/queryPageList', {params})
// 官网全列表
export const getWebsiteQueryList = params => get('/open-web/v1/queryList', {params})
// 查询富文本
export const getWebsiteDetail = params => get('/open-web/v1/detail', {params})
// 官网留言
export const websiteLeaveMessage= params => post('/open-web/v1/leaveMessage', params)
