
import { Authority, General } from '@/services/request'
import { Message } from 'element-ui'
import { getValue, blobToJSON } from '@/utils'
import handleStream from '@/utils/handle-stream'

let handleRequset = config => {
  // 在发送请求之前做些什么
  return config
}


const servicesCash = (res) => {
  // Router.push('/50X')
  Message({
    type: 'error',
    message: res.status + '服务器错误，请稍后刷新重试',
    title: '错误提示'
  })
}

// 需要处理的code码  黑名单
// eslint-disable-next-line no-unused-vars
const ACTIONS = {

}

// const SERVICES_MAP = [500, 502]
// 拦截resosne处理
let handleResponse = response => {
  // true 不进行提示  false  null 则进行提示  默认进行提示
  const HIDE_NOTIFY = response.config.headers.hideNotify
  const CODE = response.data.code
  // 判断当前接口是否为下载流文件
  if (response.config.responseType === 'blob') {
    // 先将二进制流文件 转为json判断code
    blobToJSON(response.data).then(res => {
      // 获取流文件成功
      if (res.code === 200) {
        // 获取后端返回的文件名
        const filename = getValue(response.headers['content-disposition'], 'filename')
        // 全局拦截处理excel并下载
        handleStream({ filename: decodeURIComponent(filename), data: response.data })
        return { data: null }
      } else if (res.code) { // 获取流文件失败
        // 默认都进行错误提示，需要关闭配置 hideNotify
        !HIDE_NOTIFY && Message({
          type: 'error',
          message: res.message,
          title: '错误提示'
        })
        // 存在全局特殊处理就进行处理
        ACTIONS[res.code] && ACTIONS[res.code]()
        // 在reject处理所有非200异常
        return Promise.reject(response.data)
      }
    })
  } else if (CODE === 200) return response.data
  else {
    // 默认都进行错误提示，需要关闭配置 hideNotify
    !HIDE_NOTIFY && Message({
      type: 'error',
      message: response.data.message,
      title: '错误提示'
    })
    // 存在全局特殊处理就进行处理
    ACTIONS[CODE] && ACTIONS[CODE]()
    // 在reject处理所有非200异常
    return Promise.reject(response.data)
  }
}

// 拦截的错误处理
let handleError = error => {
  if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
    Message({
      type: 'error',
      message: '请求超时',
      title: '错误提示'
    })
  }
  // 对响应错误做点什么
  // 服务器异常捕获
  // SERVICES_MAP.includes(error.response.status) && servicesCash(error.response)
  servicesCash(error.response)
  return Promise.reject(error)
}

// 添加请求拦截器
Authority.interceptors.request.use(handleRequset, handleError)
// 添加响应拦截器
Authority.interceptors.response.use(handleResponse, handleError)
General.interceptors.response.use(handleResponse, handleError)
