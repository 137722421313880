<template>
  <!--   @scroll="mouseScroll"-->
  <div id="app" ref="menu" :class="isPC?'':'phoneBox'">
    <nav-header v-show="showNav" @refresh="refresh"></nav-header>
    <transition :name="transitionName">
      <router-view class="child-view" @showNav="showNavs" ref="routerViewBox" @footerBox="isFooterBox"></router-view>
    </transition>

        <footer-box  v-if="isPC" style="z-index: 999" v-show="footerBox==1"></footer-box>
  </div>
</template>

<script>
  import NavHeader from '@/views/layout/NavHeader'
  import FooterBox from '@/views/layout/FooerBox'
  import ItemMixins from "./views/item.mixins";
  export default {
    mixins: [ItemMixins],
    name: 'App',
    components: {
      NavHeader,
      FooterBox,
    },
    data() {
      return {
        isPC: true,
        showNav: true,
        footerBox: 1,
        transitionName: 'slide-left',
        screeHeight: document.documentElement.clientHeight
      }
    },
    methods: {
      //判断是移动端还是pc端
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      },
      //判断底部显示问题
      isFooterBox(val) {
        this.footerBox = val
      },
      removeLocalStorage() {
        localStorage.removeItem('ActiveItem')
      },
      showNavs(item) {
        this.showNav = item
      },
      refresh() {
        this.$routerViewBox.getData()
      },

    },
    created() {
      //判断是pc端还是移动端
      if (this._isMobile()) {
        this.isPC = false
      } else {
        this.isPC = true
      }
    },
    mounted() {
      window.addEventListener('beforeunload', e => this.removeLocalStorage)
    }
    ,
    beforeDestroy() {
      window.removeEventListener('beforeunload', e => this.removeLocalStorage)
    },

  }
</script>

<style>
  /*}*/
  #app {
    width: 100vw;
    height: 100vh;
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto;

  }

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 0px;
  }

  .child-view {
    min-height: 90vh;
  }

  .slide-left-enter-active {
    transition: all 0.3s ease;
  }

  .slide-left-leave-active {
    transition: all 0.3s ease;
  }

  .slide-left-enter
    /* .slide-fade-leave-active for below version 2.1.8 */
  {
    transform: translateX(100%) rotateY(180deg);
    /*transform: rotate(360deg);*/
    opacity: 0;
  }

  .slide-left-leave-to {
    transform: translateX(-100%) rotateY(-180deg);

    /*transform: rotate(-360deg);*/
    opacity: 1;
  }

</style>
<style lang="less">
  /*pc详情页面样式--*/
  .timeBox {
    width: 100%;
    text-align: right;

    span {
      margin-right: 3%;
      color: #2a2a2a;
    }
  }

  .banner {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    margin: auto;

    img {
      width: 100%;
    }
  }

  .detailsBox {
    width: 70%;
    margin: auto;

    .el-page-header {
      margin: 0vh 5vh;
      font-size: 120%;

      .el-icon-back, .el-page-header__title, .el-page-header__content {
        line-height: 50px;
        -webkit-background-clip: text;
        height: 50px;
      }
    }
  }

  .detailsContent {
    margin: auto;

    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      background-image: linear-gradient(to left, #43d5fd, #0385ff);
      line-height: 50px;
      -webkit-background-clip: text;
      color: transparent;
      height: 50px;
    }

    img {
      margin: auto;
      display: inline-block;
    }
  }

  .detail_html {
    padding: 20px;

    p {
      text-indent: 20px;
      font-size: 16px;
      line-height: 25px;
    }

    //编写富文本是，图片选择居中，这里写样式实现
    .ql-align-center {
      text-align: center;
    }

    img {
      width: 100%;
    }

  }

  .no_detail {
    text-align: center;
    height: 30vh;
    line-height: 30vh;
    color: #b0afaf
  }

  /*手机端详情页样式*/
  .phoneDetailsBox {
    width: 100% !important;
    margin: auto;

    .timeBox {
      width: 100%;
      text-align: right;
      display: block;

      span {
        margin-right: 3%;
        color: #2a2a2a;
      }
    }

    .banner {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: auto;

      img {
        width: 100%;
      }
    }

    .detailsBox {
      width: 100%;
      margin: auto;

      .el-page-header {
        margin: 0vh 5vh;
        font-size: 120%;

        .el-icon-back, .el-page-header__title, .el-page-header__content {
          line-height: 50px;
          -webkit-background-clip: text;
          height: 50px;
        }
      }
    }

    .el-page-header {
      margin: 0vh 5vh;
      font-size: 120%;

      .el-icon-back, .el-page-header__title, .el-page-header__content {
        line-height: 50px;
        -webkit-background-clip: text;
        height: 50px;
      }
    }

    .detailsContent {
      margin: auto;
      height: 100%;

      .detailsContentTitle {
        display: block;
        text-align: center;
        font-size: 7rem;
        font-weight: 600;
        background-image: linear-gradient(to left, #43d5fd, #0385ff);
        line-height: 50px;
        -webkit-background-clip: text;
        color: transparent;
        min-height: 50px;
      }

      img {
        margin: auto;
        display: inline-block;
      }
    }

    .detail_html {
      padding: 5vw;
      width: 100%;
      box-sizing: border-box;

      p {
        text-indent: 25px;
        font-size: 1rem;
        line-height: 5em;
      }

      //编写富文本是，图片选择居中，这里写样式实现
      .ql-align-center {
        text-align: center;
      }

      img {
        width: 90%;
      }

    }

    .no_detail {
      text-align: center;
      height: 30vh;
      line-height: 30vh;
      color: #b0afaf
    }
  }
</style>
<style lang="less">
  .richText {
    li {
      list-style: unset;
    }

    i, em {
      font-style: italic !important
    }

    dd, dl, dt hr {
      display: block !important
    }

    /*//标题*/

    h1 {
      font-size: 2em !important;
      margin: .67em 0 !important
    }

    h2 {
      font-size: 1.5em !important;
      margin: .75em 0 !important
    }

    h3 {
      font-size: 1.17em !important;
      margin: .83em 0 !important
    }

    h4, p, blockquote, ul, fieldset, form, ol, dl, dir, menu {
      margin: 1.12em 0 !important
    }

    h5 {
      font-size: .83em !important;
      margin: 1.5em 0 !important
    }

    h6 {
      font-size: .75em !important;
      margin: 1.67em 0 !important
    }

    h1, h2, h3, h4, h5, h6, b, strong {
      font-weight: bolder !important
    }

    ol {
      list-style-type: decimal !important
    }

    ol ul, ul ol, ul ul, ol ol {
      margin-top: 0 !important;
      margin-bottom: 0 !important
    }

    ol, ul {
      margin-left: 40px !important
    }

  }
</style>
<style lang="less">
  .footerBoxAll{
    position: relative;bottom: -00px !important;
    left: 0 !important;
  }
  .phoneBox{
.menuBox{
  width: 100%;
  height: 15vw;
  text-align: center;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 0vw;

    .el-menu {
      border-right: solid 1px #e6e6e6;
      list-style: none;
      position: relative;
      margin: 0;
      left: 0;
      overflow: auto;
    }
    .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
      margin-left: 2px;
      margin-top: -0px
    }



    .el-menu--collapse .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
      -webkit-transform: none;
      transform: none
    }

    .el-menu--popup {
      z-index: 100;
      border: none;
      padding: 5px 0;
      border-radius: 2px;
      -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1)
    }

    .el-menu--popup-bottom-start {
      margin-top: 5px
    }

    .el-menu--popup-right-start {
      margin-left:0px !important ;
      margin-right: 5px
    }

    .el-menu--horizontal > .el-submenu .el-submenu__title {
      color: #909399
    }
    .el-menu--horizontal > .el-menu-item {
      border-bottom: 2px solid transparent;
      color: #909399;
      width: 20%;
      font-size: 2vw;
    }
    .el-submenu{
      padding: 0 !important;
      font-size: 1vw !important;
      width: 40% !important;

      overflow: hidden;
    }
    .el-menu-demo{
      width:220vw;
      height: 8vw;
      padding: 0vw !important;
    }
}
  }

</style>
