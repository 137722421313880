<template>
  <div class="header">
    <el-row class="nav" type="flex" align="center">
      <el-col :span="6">
        <div class="logo">
          <img src="../../assets/logo2.png"/>
        </div>
      </el-col>
      <el-col :span="18">
        <el-row class="">
          <el-col v-for="(item,index) in heardMenuList" :key="item.id" :span="navLength" class="navTab">
            <div @click="toTabs(index,item)" :class="isDivActive==index ?'divActive':' '">
              {{ item.title }}
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import Bus from '@/utils/Bus'
  import ItemMixins from "../item.mixins";
  export default {
    mixins: [ItemMixins],
    data() {
      return {
        navLength: 0,
        isDivActive: 0,
        heardMenuList: [],
      }
    },

    methods: {
      //切换菜单
      toTabs(index, item) {
        //激活的菜单索引
        sessionStorage.setItem("ActiveIndex", index);
        this.setActiveItem(item)
        this.isDivActive = index
        this.$router.replace(item.url)
      },
      async initMenu() {
        let heardList = await this.getHeadMenuList()
        let navIndex = sessionStorage.getItem('ActiveIndex')
        this.isDivActive = navIndex || 0
        this.heardMenuList = heardList
        this.navLength = Math.floor(24 / this.heardMenuList.length)
      },
    },
    created() {
      this.initMenu()
    },
    mounted() {
      Bus.$on("toCompany", val => {
        this.isDivActive = val
      });
    },
  }
</script>

<style scoped lang="less">
  .nav {
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    padding: 0px 12%;
    position: fixed;
    z-index: 99;

    top: 0px;
    left: 0;
    /*align-items: center;*/
    line-height: 80px;
    text-align: center;
    color: white;
    font-weight: 600;
    /*background: rgba(0, 0, 0, 0.5);*/
    background-image: linear-gradient(to bottom, #5b5b5b, #1a1a1a);
  }

  .logo {
    text-align: left;
    display: flex;
    align-items: center;
    height: 100%;

    img {
      display: inline-block;
      cursor: pointer;
      width: 80%;
    }
  }

  .navTab {
    div {
      width: 100%;
      height: 80%;
      font-size: 20px;
    }
    div:hover {
      color: white;
      box-shadow: 0px 0px 15px black;
      cursor: pointer;

    }

    .divActive {
      color: white;
      font-weight: 600;
      /*font-size: 120%;*/
      margin-top: -10px;
      border-radius: 15px;
      background: #0486ff;
      font-size: 26px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.67);
    }
  }



</style>
<style  scoped lang="less">
  /*手机样式*/
  .phoneBox{
    .nav {
      width: 100%;
      height: 8vw;
      box-sizing: border-box;
      padding: 0px 5%;
      position: fixed;
      z-index: 99;
      top: 0px;
      left: 0;
      /*align-items: center;*/
      line-height: 80px;
      text-align: center;
      color: white;
      font-weight: 600;
      /*background: rgba(0, 0, 0, 0.5);*/
      background-image: linear-gradient(to bottom, #5b5b5b, #1a1a1a);
      .el-row{
        height: 8vw;
      }
      .navTab {
        height: 8vw;
        line-height: 8vw;
        div {
          width: 100%;
          font-size: 2vw ;
          height: 8vw;
        }
        div:hover {
          color: white;
          box-shadow: 0px 0px 15px black;
          cursor: pointer;
        }
        .divActive {
          color: white;
          font-weight: 600;

          /*font-size: 120%;*/
          margin-top: -1vw;
          border-radius: 2vw;
          height: 8vw;
          background: #0486ff;
          font-size: 2.2vw;
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.67);
        }
      }
    }

  }
</style>
